import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
} from '../../types';
import {
  ref,
  value,
  id,
  ifOrElse,
  table,
  sumAllowNull,
  multiply,
  sumAllowEmpty,
  accountName,
  account,
} from '@agoy/document';
import helpStructureNEA from '../../types/help/NEA';
import {
  absolute,
  mapFormId,
  toInitial,
  toStructure,
  onlyNegative,
  onlyPositive,
  balanceSheetRef as balanceSheetUtilRef,
  incomeStatementRef as incomeStatementUtilRef,
  getForenklatArbokslutFieldOrARFallback,
} from '../forms-util';

const balanceSheetRef = (reference: string) =>
  balanceSheetUtilRef(reference, true);
const incomeStatementRef = (reference: string) =>
  incomeStatementUtilRef(reference, true);

const configNEA = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const NEA = mapFormId(form, 'NEA');
  const initial = {
    NEA: {
      partType: 'form' as const,
      id: NEA,
      fields: {
        datum: value(''),
        numrering_flera_NEA: value(undefined),
        namn: value(customer.name),
        personnummer: value(customer.orgNumber),
        verksamhetens_art: ref(
          id('NEA_PREVIOUS_YEAR.NEA.fields.verksamhetens_art')
        ),
        sjalvstandig: value(false),
        redovisningsansvarig_personnummer: value(''),
        // With the migration to connect NE and NEA forms to AR, the usage of ifOrElse can compensate
        // for not storing ForenklatArsbokslut values derived from AR correctly in our DB
        B_1_immateriella: getForenklatArbokslutFieldOrARFallback(
          'B_1',
          'ImmateriellaAnlaggningstillgangar',
          balanceSheetRef
        ),
        B_2_byggnader: getForenklatArbokslutFieldOrARFallback(
          'B_2',
          'ByggnaderMarkanlaggningar',
          balanceSheetRef
        ),
        B_3_mark: getForenklatArbokslutFieldOrARFallback(
          'B_3',
          'MarkSomInteSkrivas',
          balanceSheetRef
        ),
        B_4_maskiner: getForenklatArbokslutFieldOrARFallback(
          'B_4',
          'MaskinerInventarier',
          balanceSheetRef
        ),
        B_5_ovriga_anlaggningstillgangar:
          getForenklatArbokslutFieldOrARFallback(
            'B_5',
            'OvrigaAnlaggningstillgangar',
            balanceSheetRef
          ),
        B_6_varulager: getForenklatArbokslutFieldOrARFallback(
          'B_6',
          'Varulager',
          balanceSheetRef
        ),
        B_7_kundfordringar: getForenklatArbokslutFieldOrARFallback(
          'B_7',
          'Kundfordringar',
          balanceSheetRef
        ),
        B_8_ovriga_fordringar: getForenklatArbokslutFieldOrARFallback(
          'B_8',
          'OvrigaFordringar',
          balanceSheetRef
        ),
        B_9_kassa: getForenklatArbokslutFieldOrARFallback(
          'B_9',
          'KassaBank',
          balanceSheetRef
        ),
        B_10_eget_kapital: getForenklatArbokslutFieldOrARFallback(
          'B_10',
          'EgetKapital',
          balanceSheetRef
        ),
        B_11_obeskattade: value(
          undefined,
          'Ska inte fyllas i av den som upprättar förenklat årsbokslut'
        ),
        B_12_avsattningar: value(
          undefined,
          'Ska inte fyllas i av den som upprättar förenklat årsbokslut'
        ),

        B_13_laneskulder: getForenklatArbokslutFieldOrARFallback(
          'B_13',
          'Laneskulder',
          balanceSheetRef
        ),
        B_14_skatteskulder: getForenklatArbokslutFieldOrARFallback(
          'B_14',
          'Skatteskulder',
          balanceSheetRef
        ),
        B_15_leverantorsskulder: getForenklatArbokslutFieldOrARFallback(
          'B_15',
          'Leverantorsskulder',
          balanceSheetRef
        ),
        B_16_ovriga_skulder: getForenklatArbokslutFieldOrARFallback(
          'B_16',
          'OvrigaSkulder',
          balanceSheetRef
        ),
        R_1_forsaljning: getForenklatArbokslutFieldOrARFallback(
          'R_1',
          'netSales',
          incomeStatementRef
        ),
        R_2_momsfria: getForenklatArbokslutFieldOrARFallback(
          'R_2',
          'MomsfriaIntakter',
          incomeStatementRef
        ),
        R_3_bil: getForenklatArbokslutFieldOrARFallback(
          'R_3',
          'BilBostadsforman',
          incomeStatementRef
        ),
        R_4_ranteintakter: getForenklatArbokslutFieldOrARFallback(
          'R_4',
          'Ranteintakter',
          incomeStatementRef
        ),
        R_5_varor: getForenklatArbokslutFieldOrARFallback(
          'R_5',
          'VarorMaterialTjanster',
          incomeStatementRef,
          true
        ),
        R_6_ovriga_kostnader: getForenklatArbokslutFieldOrARFallback(
          'R_6',
          'OvrigaExternaKostnader',
          incomeStatementRef,
          true
        ),
        R_7_anstalld: getForenklatArbokslutFieldOrARFallback(
          'R_7',
          'AnstalldPersonal',
          incomeStatementRef,
          true
        ),
        R_8_rantekostnader: getForenklatArbokslutFieldOrARFallback(
          'R_8',
          'Rantekostnader',
          incomeStatementRef,
          true
        ),
        R_9_avskrivningar: getForenklatArbokslutFieldOrARFallback(
          'R_9',
          'NedskrivningarByggnader',
          incomeStatementRef,
          true
        ),
        R_10_avskrivningar_maskiner: getForenklatArbokslutFieldOrARFallback(
          'R_10',
          'NedskrivningarMaskiner',
          incomeStatementRef,
          true
        ),
        R_11_bokfort_resultat_1: getForenklatArbokslutFieldOrARFallback(
          'R_11',
          'AretsResultat',
          incomeStatementRef
        ),
        R_12_bokfort_resultat_2: ref(id('NEA.fields.R_11_bokfort_resultat_1')),
        R_13_bokforda_kostnader: ref(
          sumAllowNull(id('NEA.help.improvements.*.amount'))
        ),
        R_14_bokforda_intakter: ref(
          absolute(sumAllowNull(id('NEA.help.renovation.*.amount')))
        ),
        R_15_intakter: value(undefined),
        R_16_kostnader: value(undefined),
        R_17_sammanlagt: ref(
          sumAllowEmpty(
            id('NEA.fields.R_12_bokfort_resultat_2'),
            id('NEA.fields.R_13_bokforda_kostnader'),
            multiply(-1, id('NEA.fields.R_14_bokforda_intakter')),
            id('NEA.fields.R_15_intakter'),
            multiply(-1, id('NEA.fields.R_16_kostnader'))
          )
        ),
        R_18_underskott: value(undefined),
        R_18_overskott: value(undefined),
        R_19_aterforing: value(undefined),
        R_20_ovriga_skatt_kostnader: value(undefined),
        R_21_ovriga_skatt_intakter: value(undefined),
        R_22_overskott: ref(
          onlyPositive(
            sumAllowEmpty(
              id('NEA.fields.R_17_sammanlagt'),
              multiply(-1, id('NEA.fields.R_18_underskott')),
              id('NEA.fields.R_18_overskott'),
              id('NEA.fields.R_19_aterforing'),
              multiply(-1, id('NEA.fields.R_20_ovriga_skatt_kostnader')),
              id('NEA.fields.R_21_ovriga_skatt_intakter')
            )
          )
        ),
        R_23_underskott: ref(
          onlyNegative(
            sumAllowEmpty(
              id('NEA.fields.R_17_sammanlagt'),
              multiply(-1, id('NEA.fields.R_18_underskott')),
              id('NEA.fields.R_18_overskott'),
              id('NEA.fields.R_19_aterforing'),
              multiply(-1, id('NEA.fields.R_20_ovriga_skatt_kostnader')),
              id('NEA.fields.R_21_ovriga_skatt_intakter')
            )
          )
        ),
      },
      sru: {
        // PersOrgnr below uses the value from personnummer field above
        PersOrgnr: ref(id('NEA.fields.personnummer')),
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7014': ref(id('NEA.fields.numrering_flera_NEA')),
        '7020': ref(id('NEA.fields.verksamhetens_art')),
        '7024': ref(id('NEA.fields.sjalvstandig')),
        '7025': ref(id('NEA.fields.redovisningsansvarig_personnummer')),
        '7200': ref(id('NEA.fields.B_1_immateriella')),
        '7210': ref(id('NEA.fields.B_2_byggnader')),
        '7211': ref(id('NEA.fields.B_3_mark')),
        '7212': ref(id('NEA.fields.B_4_maskiner')),
        '7213': ref(id('NEA.fields.B_5_ovriga_anlaggningstillgangar')),
        '7240': ref(id('NEA.fields.B_6_varulager')),
        '7250': ref(id('NEA.fields.B_7_kundfordringar')),
        '7260': ref(id('NEA.fields.B_8_ovriga_fordringar')),
        '7280': ref(id('NEA.fields.B_9_kassa')),
        '7300': ref(id('NEA.fields.B_10_eget_kapital')),
        '7320': ref(id('NEA.fields.B_11_obeskattade')),
        '7330': ref(id('NEA.fields.B_12_avsattningar')),
        '7380': ref(id('NEA.fields.B_13_laneskulder')),
        '7381': ref(id('NEA.fields.B_14_skatteskulder')),
        '7382': ref(id('NEA.fields.B_15_leverantorsskulder')),
        '7383': ref(id('NEA.fields.B_16_ovriga_skulder')),
        '7400': ref(id('NEA.fields.R_1_forsaljning')),
        '7401': ref(id('NEA.fields.R_2_momsfria')),
        '7402': ref(id('NEA.fields.R_3_bil')),
        '7403': ref(id('NEA.fields.R_4_ranteintakter')),
        '7500': ref(id('NEA.fields.R_5_varor')),
        '7501': ref(id('NEA.fields.R_6_ovriga_kostnader')),
        '7502': ref(id('NEA.fields.R_7_anstalld')),
        '7503': ref(id('NEA.fields.R_8_rantekostnader')),
        '7504': ref(id('NEA.fields.R_9_avskrivningar')),
        '7505': ref(id('NEA.fields.R_10_avskrivningar_maskiner')),
        '7440': ref(id('NEA.fields.R_11_bokfort_resultat_1')),
        '7600': ref(id('NEA.fields.R_12_bokfort_resultat_2')),
        '7601': ref(id('NEA.fields.R_13_bokforda_kostnader')),
        '7700': ref(id('NEA.fields.R_14_bokforda_intakter')),
        '7602': ref(id('NEA.fields.R_15_intakter')),
        '7701': ref(id('NEA.fields.R_16_kostnader')),
        // R17 has no SRU code
        '7702': ref(id('NEA.fields.R_18_overskott')),
        '7603': ref(id('NEA.fields.R_18_underskott')),
        '7605': ref(id('NEA.fields.R_19_aterforing')),
        '7704': ref(id('NEA.fields.R_20_ovriga_skatt_kostnader')),
        '7604': ref(id('NEA.fields.R_21_ovriga_skatt_intakter')),
        '7680': ref(id('NEA.fields.R_22_overskott')),
        '7780': ref(id('NEA.fields.R_23_underskott')),
      },
      help: {
        _type: 'NEA' as const,
        improvements: table(
          'NEA.help.improvements',
          'account',
          'accountName',
          'amount'
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                value('5099'),
                ref(accountName('5099')),
                ref(account('5099'))
              )
              .addRow(
                '2',
                value('5199'),
                ref(accountName('5199')),
                ref(account('5199'))
              )
              .addRow(
                '3',
                value('6072'),
                ref(accountName('6072')),
                ref(account('6072'))
              )
              .addRow(
                '4',
                value('6342'),
                ref(accountName('6342')),
                ref(account('6342'))
              )
              .addRow(
                '5',
                value('6352'),
                ref(accountName('6352')),
                ref(account('6352'))
              )
              .addRow(
                '6',
                value('6982'),
                ref(accountName('6982')),
                ref(account('6982'))
              )
              .addRow(
                '7',
                value('6992'),
                ref(accountName('6992')),
                ref(account('6992'))
              )
              .addRow(
                '8',
                value('6998'),
                ref(accountName('6998')),
                ref(account('6998'))
              )
              .addRow(
                '9',
                value('7622'),
                ref(accountName('7622')),
                ref(account('7622'))
              )
              .addRow(
                '10',
                value('7632'),
                ref(accountName('7632')),
                ref(account('7632'))
              )
              .addRow(
                '11',
                value('8423'),
                ref(accountName('8423')),
                ref(account('8423'))
              )
              .build()
          )
          .newRowTemplate(
            value(0),
            ref(accountName(id('$id.account'))),
            ref(account(id('$id.account')))
          )
          .build(),
        renovation: table(
          'NEA.help.renovation',
          'account',
          'accountName',
          'amount'
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                value('8254'),
                ref(accountName('8254')),
                ref(account('8254'))
              )
              .addRow(
                '2',
                value('8314'),
                ref(accountName('8314')),
                ref(account('8314'))
              )
              .build()
          )
          .newRowTemplate(
            value(0),
            ref(accountName(id('$id.account'))),
            ref(account(id('$id.account')))
          )
          .build(),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form, { NEA: helpStructureNEA }),
  };
};

export default configNEA;
