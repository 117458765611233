import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';

import useClientDataLayer from 'data/client/useClientDataLayer';
import SpecificationInfo from '_shared/components/SpecificationInfo';
import { InputData } from '_reconciliation/types';

import SpecificationTable from './SpecificationTable';
import PeriodDataContext from '../PeriodDataContext';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Dialog = styled(MuiDialog)`
  @media print {
    .MuiDialog-paper {
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
`;

const TopBarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  min-width: 800px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

type SpecificationPrintModalProps = {
  specification: Client.LegacySpecification[];
  account: string;
  accountName: string;
  open: boolean;
  userData: InputData;
  handleShow: (value: boolean) => void;
};

const SpecificationPrintModal = ({
  specification,
  account,
  accountName,
  open,
  userData,
  handleShow,
}: SpecificationPrintModalProps): JSX.Element => {
  const { clientId, period, financialYear, periodType } =
    useContext(PeriodDataContext);

  const clientDataLayer = useClientDataLayer(clientId);
  const [yearIB, setYearIB] = useState<number | undefined>();

  useEffect(() => {
    const subscription = clientDataLayer.reconciliation
      .getAccount(
        financialYear.start,
        financialYear.end,
        'financialYear',
        account,
        periodType === 'yearEnd'
      )
      .subscribe((result) => {
        if (result.ok) {
          if (result.val) {
            setYearIB(result.val.ib);
          } else {
            // eslint-disable-next-line no-console
            console.warn('account not found');
          }
        }
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [
    account,
    clientDataLayer.reconciliation,
    financialYear.end,
    financialYear.start,
    periodType,
  ]);

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    });
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleShow(false)} maxWidth="lg">
        <DialogContent>
          <TopBarActions>
            <LoadingContainer>
              <PrintButton aria-label="print" onClick={handlePrint}>
                <PrintIcon />
              </PrintButton>
            </LoadingContainer>
            <CloseButton aria-label="close" onClick={() => handleShow(false)}>
              <CloseIcon />
            </CloseButton>
          </TopBarActions>
          <Content>
            {financialYear && yearIB !== undefined && (
              <SpecificationInfo
                period={period}
                financialYear={financialYear}
                inputData={userData}
                accountName={accountName}
                accountNumber={account}
                yearIB={yearIB}
              />
            )}
            <TableWrapper>
              <SpecificationTable
                specification={specification}
                accountNumber={account}
                print
              />
            </TableWrapper>
          </Content>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default SpecificationPrintModal;
