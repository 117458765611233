import React, { useContext } from 'react';
import {
  CoverLetterFields,
  CoverLetterPart,
  TaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';
import { TaxDeclarationHelp } from '@agoy/tax-declaration-document/src/types/help';
import { useSelector } from 'redux/reducers';

import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import CoverLetter from './CoverLetter';

export const CoverLetterPrint = () => {
  const { state } = useContext(PrintStateContext);
  if (!state.additionalPages) {
    return null;
  }
  const { orgName, postalCode, city } = state.additionalPages;

  const fields = state.additionalPages.document.CL.fields as CoverLetterFields;
  const help = state.additionalPages.document.CL.help as TaxDeclarationHelp;

  return (
    <CoverLetter
      help={help}
      fields={fields}
      postalCode={postalCode}
      city={city}
      orgName={orgName}
    />
  );
};

type CoverLetterWebProps = {
  document: TaxDeclarationFormPartData | undefined;
};

function isCoverLetterPart(
  part: TaxDeclarationFormPartData
): part is CoverLetterPart {
  return (part as CoverLetterPart).partType === 'coverLetter';
}

export const CoverLetterWeb = ({ document }: CoverLetterWebProps) => {
  const organisation = useSelector((state) => state.organisation);

  const { name: orgName, postalCode, city } = organisation;

  if (!document || !isCoverLetterPart(document)) {
    return null;
  }

  const { fields, help } = document;

  return (
    <CoverLetter
      fields={fields}
      help={help}
      postalCode={postalCode}
      city={city}
      orgName={orgName ?? ''}
    />
  );
};
