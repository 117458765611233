/* eslint-disable react/jsx-props-no-spreading */
import { AgoyDocumentStructure } from '@agoy/document';
import { TaxDeclarationFormPartData } from '@agoy/tax-declaration-document';
import { PeriodDataContextType } from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/periodUtils';
import React, { createContext, useState } from 'react';
import { RootState } from 'redux/reducers';

export type PrintState = Partial<RootState> & {
  /**
   * Content for the PeriodDataContext in the print view
   */
  periodData?: Partial<PeriodDataContextType> &
    Pick<
      PeriodDataContextType,
      'financialYear' | 'period' | 'lastPeriod' | 'periodType' | 'yearEndPeriod'
    >;

  /**
   * Additional parameters required for printing state
   */
  parameters?: Record<string, unknown>;
  additionalPages?: {
    orgName?: string;
    postalCode?: string;
    city?: string;
    definition: AgoyDocumentStructure;
    document: Record<string, TaxDeclarationFormPartData>;
  };
};

type PrintStateContextType = {
  setState(state: PrintState);
  state: PrintState;
};

const PrintStateContext = createContext<PrintStateContextType>(
  {} as unknown as PrintStateContextType
);

export const withPrintState =
  (Component: React.FC) =>
  (props: Record<string, unknown>): JSX.Element => {
    const [state, setState] = useState({});

    return (
      <PrintStateContext.Provider value={{ state, setState }}>
        <Component {...props} />
      </PrintStateContext.Provider>
    );
  };
export default PrintStateContext;
