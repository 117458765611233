import React, { memo, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { useApiSdk, asResultClass } from 'api-sdk';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import {
  mapFromSpecification,
  mapToSpecification,
} from '_reconciliation/util/mapSpecification';
import { addGlobalErrorMessage } from 'redux/actions';
import Button from '_shared/components/Buttons/Button';
import PeriodDataContext from '../PeriodDataContext';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

interface TransferSpecificationProps {
  specification: Client.LegacySpecification[];
  accountNumber: string;
  onClickPrint: () => void;
  setCurrentSpecification: (
    specification: Client.LegacySpecification[]
  ) => void;
}

export const withoutSpaces = (s: string): string => {
  return s ? s.trim() : '';
};

export const TransferSpecification = memo(
  ({
    specification,
    accountNumber,
    setCurrentSpecification,
    onClickPrint,
  }: TransferSpecificationProps) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const sdk = useApiSdk();

    const [previousSpecification, setPreviousSpecification] = useState<
      Client.LegacySpecification[] | undefined
    >();

    const {
      clientId,
      financialYear,
      period,
      periodType,
      parentPeriodType,
      periodLocked,
      nextPeriod,
      nextPeriodFinancialYear,
      nextPeriodLocked,
      previousPeriod,
      previousPeriodFinancialYear,
      lastPeriodLocked,
      yearEndPeriod,
      getPeriodUserInput,
    } = useContext(PeriodDataContext);

    useEffect(() => {
      (async () => {
        if (previousPeriod) {
          const prevSpecResult = await asResultClass(
            sdk.getLegacySpecifications({
              clientid: clientId,
              periodId: previousPeriod.id,
              accountNumbers: [parseInt(accountNumber, 10)],
            })
          );
          if (prevSpecResult?.ok) {
            const prevSpecification =
              prevSpecResult.val.accounts[accountNumber] || [];

            setPreviousSpecification(
              prevSpecification.map(mapFromSpecification)
            );
          }
        }
      })();
    }, [
      previousPeriodFinancialYear,
      previousPeriod,
      accountNumber,
      periodType,
      sdk,
      clientId,
    ]);

    const isNotEmpty = !!specification.find(
      (spec) =>
        withoutSpaces(spec.description) !== '' ||
        (spec.amount !== '0' && spec.amount !== '') ||
        withoutSpaces(spec.reference) !== ''
    );
    const canTransferToNextPeriod = nextPeriod && isNotEmpty;
    const canTransferToLastPeriod =
      period.id !== yearEndPeriod.id && isNotEmpty;

    const transferLegacySpecifications = async (
      toFinancialYear: FinancialYear,
      toPeriod: Period,
      specificationsToCopy: Client.LegacySpecification[]
    ) => {
      // get specification of last year end period
      const specResult = await asResultClass(
        sdk.getLegacySpecifications({
          clientid: clientId,
          periodId: toPeriod.id,
          accountNumbers: [parseInt(accountNumber, 10)],
        })
      );

      if (specResult.ok) {
        const currentSpecifications =
          specResult.val.accounts[accountNumber] || [];

        // concat current and new specifications of last year end period
        const updatedSpecifications = [
          ...currentSpecifications,
          ...specificationsToCopy.map(mapToSpecification),
        ];

        const result = await asResultClass(
          sdk.updateLegacySpecifications({
            clientid: clientId,
            periodId: toPeriod.id,
            accountNumber: parseInt(accountNumber, 10),
            requestBody: updatedSpecifications,
          })
        );

        if (result.ok) {
          setCurrentSpecification(
            updatedSpecifications.map(mapFromSpecification)
          );
        }

        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    };

    const transferSpecToNextPeriod = async () => {
      if (nextPeriod && nextPeriodFinancialYear) {
        if (nextPeriodFinancialYear !== financialYear) {
          await getPeriodUserInput(
            nextPeriod,
            nextPeriodFinancialYear,
            accountNumber
          );
        }

        const specificationsToCopy: Client.LegacySpecification[] = [];

        specification.forEach((row) => {
          if (
            row.description === '' &&
            (row.amount === '' || row.amount === '0')
          ) {
            return;
          }

          specificationsToCopy.push({
            ...row,
            description: withoutSpaces(row.description),
          });
        });

        transferLegacySpecifications(
          nextPeriodFinancialYear,
          nextPeriod,
          specificationsToCopy
        );
      }
    };

    const pullSpecFromPrevPeriod = () => {
      const specificationsToCopy: Client.LegacySpecification[] = [];

      previousSpecification?.forEach((prevSpec) => {
        const newPrevSpec = {
          ...prevSpec,
          description: withoutSpaces(prevSpec.description),
        };
        if (newPrevSpec.description !== '') {
          specificationsToCopy.push(newPrevSpec);
        }
      });
      transferLegacySpecifications(financialYear, period, specificationsToCopy);
    };

    const transferSpecLastPeriod = () => {
      const specificationsToCopy: Client.LegacySpecification[] = [];

      specification.forEach((row) => {
        const newRow = { ...row, description: withoutSpaces(row.description) };
        if (newRow.description !== '') {
          specificationsToCopy.push(newRow);
        }
      });

      transferLegacySpecifications(
        financialYear,
        yearEndPeriod,
        specificationsToCopy
      );
    };

    const printSpecifications = () => {
      onClickPrint();
    };

    return (
      <ButtonWrapper>
        {periodType !== 'dead' && (
          <Button
            label={formatMessage({ id: 'hidden.transactions.transferComment' })}
            disabled={!canTransferToNextPeriod || nextPeriodLocked !== false}
            onClick={transferSpecToNextPeriod}
            size="small"
            variant="text"
          />
        )}

        {periodType !== 'dead' && previousSpecification && (
          <Button
            label={formatMessage({ id: 'hidden.transactions.fetchComment' })}
            disabled={periodLocked}
            onClick={pullSpecFromPrevPeriod}
            size="small"
            variant="text"
          />
        )}

        <Button
          label={formatMessage(
            {
              id: 'hidden.transactions.commentToEnd',
            },
            {
              target: formatMessage({
                id: `periodType.${parentPeriodType ?? 'yearEndPeriod'}`,
              }),
            }
          )}
          disabled={!canTransferToLastPeriod || lastPeriodLocked !== false}
          onClick={transferSpecLastPeriod}
          size="small"
          variant="text"
        />

        {periodType !== 'dead' && (
          <Button
            label={formatMessage({ id: 'hidden.specification.print' })}
            onClick={printSpecifications}
            size="small"
            variant="text"
          />
        )}
      </ButtonWrapper>
    );
  }
);

export default TransferSpecification;
