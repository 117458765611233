import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Field, numberValue, stringValue, booleanValue } from '@agoy/document';
import { getClasses } from '@agoy/common';

import AutosizeTextArea from '_shared/components/Inputs/v2/AutosizeTextarea';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import When from '_shared/components/When/When';
import SharedCheckbox from '_shared/components/Controls/Checkbox';
import { SimplifiedDatePicker } from '_shared/components/Inputs';
import Typography from '_shared/components/Typography/Typography';

const COVER_LETTER_KEY = 'CL';

const baseStyle = css`
  width: 100%;
  border-radius: 2px;
  border: 0px;
  background: #f2f4ff;
  padding: 2px;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Exo 2';

  :hover {
    background-color: color-mix(in srgb, #000 5%, #f2f4ff 100%);
  }

  :focus {
    outline: 1px solid #7779ff;
    background: #fff;
  }

  @media print {
    background: none;
  }
`;

const StyledInput = styled.input`
  ${baseStyle}

  &.title {
    font-weight: 700;
  }
`;

const StyledTextArea = styled.textarea`
  ${baseStyle}
  resize: none;
  overflow: hidden;
`;

export const StyledDateField = styled(SimplifiedDatePicker)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  flex: 1;

  .MuiInputBase-input {
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 2px;
  }
  .MuiInputBase-root {
    background: #f2f4ff;
    padding-right: 0;
  }

  .MuiInputBase-root:hover {
    background: #dddfe9;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  fieldset {
    border: none;
  }
`;

type InputProps = {
  field: Field;
  keyValue: string;
  label?: string;
  title?: boolean;
  className?: string;
  multiline?: boolean;
  type?: 'date';
};

const Input = ({
  field,
  keyValue,
  label,
  title = false,
  className,
  multiline = false,
  type,
}: InputProps) => {
  const dispatch = useDispatch();
  const value = stringValue(field);
  const inputClasses = getClasses({ title });

  // We check if we are in print context here
  const { state } = useContext(PrintStateContext);
  const isPrint = state?.additionalPages;

  // In print and if there is no value in field without label return null
  if (isPrint && !value && !label && field.type !== 'number') {
    return <span />;
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      updateTaxDeclarationFormField(COVER_LETTER_KEY, keyValue, e.target.value)
    );
  };

  const handleCurrencyChange = (val: number | undefined) => {
    dispatch(updateTaxDeclarationFormField(COVER_LETTER_KEY, keyValue, val));
  };

  const handleChangeDate = (date) => {
    if (!date) {
      return;
    }
    dispatch(updateTaxDeclarationFormField(COVER_LETTER_KEY, keyValue, date));
  };

  if (multiline) {
    return (
      <AutosizeTextArea
        className={className}
        as={StyledTextArea}
        id={keyValue}
        value={value}
        onChange={handleChange}
      />
    );
  }

  if (type === 'date') {
    return (
      <StyledDateField
        editing={!isPrint}
        value={value}
        size="small"
        onChange={handleChangeDate}
      />
    );
  }

  if (field.type === 'number') {
    return (
      <CurrencyField
        value={numberValue(field)}
        onValueChange={handleCurrencyChange}
        Input={StyledInput}
      />
    );
  }

  return (
    <div className={className}>
      <When isTrue={typeof label === 'string'}>
        <Typography htmlFor={keyValue} variant="body2" as="label">
          {label}
        </Typography>
      </When>
      <StyledInput
        className={inputClasses}
        id={keyValue}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

type RadioProps = {
  children: React.ReactElement<RadioOptionProps>[];
  defaultValue?: string;
  onChange: (value: string) => void;
};

const Radio = ({ children, onChange, defaultValue }: RadioProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  );

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          checked: child.props.value === selectedValue,
          onChange: () => handleChange(child.props.value),
        });
      })}
    </>
  );
};

const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;

  input[type='radio'] {
    appearance: none;
    background: #f2f4ff;
    margin: 0;
    width: 18px;
    height: 18px;

    border: 1px solid #000;
    flex-shrink: 0;
  }

  input[type='radio']:hover {
    background-color: color-mix(in srgb, #000 5%, #f2f4ff 100%);
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  fill: #002517;
  pointer-events: none;

  &.checked {
    opacity: 1;
  }
`;

type RadioOptionProps = {
  label: string;
  checked?: boolean;
  onChange?: () => void;
  value: string;
};

const RadioOption = ({
  label,
  onChange,
  checked = false,
  value,
}: RadioOptionProps) => {
  const classes = getClasses({ checked });

  return (
    <RadioWrapper>
      <input type="radio" id={value} checked={checked} onChange={onChange} />
      <StyledSvg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g>
          <rect x="4" y="4" width="10" height="10" rx="2" />
        </g>
      </StyledSvg>
      <Typography as="label" variant="body2" htmlFor={value} margin="none">
        {label}
      </Typography>
    </RadioWrapper>
  );
};

type CheckBoxProps = {
  field: Field;
  keyValue: string;
  label: string;
};

const Checkbox = ({ field, keyValue, label }: CheckBoxProps) => {
  const dispatch = useDispatch();

  const handleChange = (checked: boolean) => {
    dispatch(
      updateTaxDeclarationFormField(COVER_LETTER_KEY, keyValue, checked)
    );
  };

  return (
    <SharedCheckbox
      type="tax"
      checked={booleanValue(field)}
      onChange={handleChange}
      label={label}
    />
  );
};

export { Input, Checkbox, Radio, RadioOption };
