import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ccyFormat, reformatDate } from '@agoy/common';
import styled from '@emotion/styled';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { addGlobalMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { formatPeriodToMonthAndYear } from 'utils';
import { getPeriodYear } from 'utils/periodManipulation';
import { paginateArray } from 'utils/SieParser/transactionUtils';
import { setCustomerInvoices } from '_reconciliation/redux/accounting-view/actions';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';
import {
  CustomerInvoice,
  InputData,
  InvoiceAttachmentResponse,
  InvoicesFilter,
  LastFetchedDataStateType,
} from '_reconciliation/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import PdfStringModal from '_shared/components/Modal/PdfStringModal';
import Paginator from '_shared/components/Paginator';
import PeriodSummary from '_shared/components/PeriodSummary';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '_shared/components/Table/BorderTable';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import { UnreachableCaseError } from 'utils/types';
import { FinancialYear } from '@agoy/api-sdk-core';
import { TimePeriod } from '@agoy/document';

import { endOfMonth } from 'date-fns';
import { isEqual } from 'lodash';
import { parse, reformat, format, parseFormat } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';
import PeriodPicker from '../PeriodPicker';
import filterInvoices from './customerInvoicesUtil';
import InvoiceFilters from './InvoiceFilters';
import InvoicePreviewButton from './InvoicePreviewButton';
import { InvoicesPreviewModal } from './InvoicesPreview';
import PrintButton from './PrintButton';
import PeriodDataContext from '../PeriodDataContext';

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const LoadingWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`;

const TableWrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  overflow-x: auto;
`;

const FiltersWrapper = styled.div`
  display: flex;
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(1)}px 0;
  justify-content: space-between;
  align-items: center;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const Spinner = styled(CircularProgress)`
  top: 15%;
  left: 40%;
`;

const DateWrapper = styled.div`
  margin-left: auto;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

const headers: MessageDescriptor[] = [
  { id: 'hidden.invoices.Id' },
  { id: 'hidden.invoices.InvoiceNumber' },
  { id: 'hidden.invoices.InvoiceOCR' },
  { id: 'hidden.invoices.CustomerNumber' },
  { id: 'hidden.invoices.CustomerName' },
  { id: 'hidden.invoices.InvoiceDate' },
  { id: 'hidden.invoices.InvoiceTotalAmount' },
  { id: 'hidden.invoices.InvoiceSaldo' },
  { id: 'hidden.invoices.InvoiceCurrency' },
  { id: 'hidden.invoices.InvoiceDueDate' },
  { id: 'hidden.invoices.InvoicePaymentDate' },
  { id: 'hidden.invoices.InvoiceStatus' },
];

type CustomerInvoicesViewProps = {
  userData: InputData;
  account: string;
};

const CustomerInvoicesView = ({
  userData,
  account,
}: CustomerInvoicesViewProps) => {
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const notificationService = useContext(NotificationContext);
  const {
    ub,
    ib,
    psaldo,
    saldo: userDataSaldo,
    legacySpecifications = [],
  } = userData || {};
  const { clientId, groupedPeriods, financialYear, period } =
    useContext(PeriodDataContext);

  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;

  const initData = useSelector(
    clientYear(clientId, financialYear, (state) => state.customerInvoices)
  );
  const currentPeriodYear: number = getPeriodYear(period.start);
  const date = period.end;

  const [balanceTotal, setBalanceTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState<CustomerInvoice[]>([]);
  const [filteredData, setFilteredData] = useState<CustomerInvoice[]>([]);
  const [openPreview, setOpenPreview] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [invoicesUpdateState, setInvoicesUpdateState] =
    useState<LastFetchedDataStateType>(undefined);

  // invoice attachment preview state
  const [invoicePreviewVisible, setInvoicePreviewVisible] = useState(false);
  const [invoicePreview, setInvoicePreview] =
    useState<InvoiceAttachmentResponse>();

  // STATE
  const [filterBy, setFilterBy] = useState<InvoicesFilter>({
    account,
    periods: {},
    statusList: [],
  });

  const fetchInvoices = useCallback(
    async (
      fetchClientId: string,
      fetchDate: string[],
      fetchFinancialYear: FinancialYear
    ) => {
      const financialYearAsString =
        TimePeriod.fromISODates(fetchFinancialYear).value;
      try {
        setIsLoading(true);

        const result = await asResultClass(
          sdk.getCustomerInvoices({
            clientid: fetchClientId,
            date: fetchDate,
          })
        );
        if (!result.err) {
          const invoicesResponse = result.val;
          setInvoicesUpdateState(invoicesResponse.reportData.updateState);
          dispatch(
            setCustomerInvoices(
              fetchClientId,
              financialYearAsString,
              invoicesResponse
            )
          );
        } else {
          dispatch(
            addGlobalMessage('error', 'hidden.invoices.FetchStatus.error')
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch(
          addGlobalMessage('error', 'hidden.invoices.FetchStatus.error')
        );
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, sdk]
  );

  const getUpdateState = (state: LastFetchedDataStateType) => {
    switch (state) {
      case 'completed': {
        return <AccessTimeIcon />;
      }
      case 'running': {
        return <UpdateIcon />;
      }
      case 'invoices_fail':
      case 'payments_fail':
      case undefined: {
        return <ReportProblemOutlinedIcon />;
      }
      default:
        throw new UnreachableCaseError(state);
    }
  };
  // EFFECTS
  useEffect(() => {
    // update date when other period in same account is selected
    setFilterBy((f) => {
      const periods = groupedPeriods.reduce(
        (result, p) => ({
          ...result,
          [reformatDate(p.start, 'yyyy-MM-dd', 'yyyyMM')]: true,
        }),
        {}
      );
      if (isEqual(periods, f.periods)) {
        return f;
      }
      return {
        ...f,
        periods,
      };
    });
  }, [groupedPeriods]);

  useEffect(() => {
    // set filtered data
    const data = filterInvoices({
      data: initData?.invoices ?? [],
      filter: filterBy,
    });
    setFilteredData(data);

    // calculate sum of all transactions
    setBalanceTotal(
      data.reduce((acc, invoice) => {
        return acc + invoice.balance;
      }, 0)
    );

    // reset pagination
    setPageNumber(1);
  }, [initData, filterBy]);

  useEffect(() => {
    setPaginatedData(
      paginateArray({
        pageNumber,
        array: filteredData,
      })
    );
  }, [pageNumber, filteredData]);

  useEffect(() => {
    const formatDate = Object.keys(filterBy.periods).map((periodStartDate) =>
      format(endOfMonth(parse(periodStartDate, 'yyyyMM')), 'yyyy-MM-dd')
    );

    if (!formatDate.length) return;
    fetchInvoices(clientId, formatDate, financialYear);
  }, [clientId, fetchInvoices, financialYear, filterBy.periods]);

  useEffect(() => {
    if (clientId && financialYear) {
      const sub = notificationService?.subscribe(
        {
          topic: 'fortnox-customer-invoices',
          clientId,
        },
        (result) => {
          if (result.ok && result.val.topic === 'fortnox-customer-invoices') {
            // if update finished to reload invocies from DB
            switch (result.val.status) {
              case 'completed': {
                fetchInvoices(clientId, [date], financialYear);
                dispatch(
                  addGlobalMessage(
                    'success',
                    'hidden.invoices.FetchStatus.success'
                  )
                );
                break;
              }
              case 'running': {
                setInvoicesUpdateState('running');
                break;
              }
              default: {
                setInvoicesUpdateState(result.val.status);
                dispatch(
                  addGlobalMessage('error', 'hidden.invoices.FetchStatus.error')
                );
                break;
              }
            }
          }
        }
      );

      return () => {
        sub?.unsubscribe();
      };
    }
    return undefined;
  }, [
    clientId,
    date,
    dispatch,
    fetchInvoices,
    financialYear,
    notificationService,
  ]);

  useEffect(() => {
    if (initData) {
      const statusList = initData.statusList.map((statusTitle) => ({
        title: statusTitle,
        active: false,
      }));
      setFilterBy((f) => ({ ...f, statusList }));
    }
  }, [initData]);

  // CALLBACKS
  const handlePeriodSelect = useCallback((periods) => {
    setFilterBy((f) =>
      isEqual(periods, f.periods)
        ? f
        : {
            ...f,
            periods: { ...periods },
          }
    );
  }, []);

  const handleStatusSelect = (statusString: string) => {
    const statusList = filterBy.statusList.map((item) => {
      if (
        (item.title === 'unpaid_overdue' && statusString === 'unpaid') ||
        item.title === statusString
      ) {
        return { ...item, active: true };
      }
      return item;
    });

    setFilterBy((f) => ({ ...f, statusList }));
  };

  const handleStatusDeSelect = (statusString: string) => {
    const statusList = filterBy.statusList.map((item) =>
      item.title === statusString ? { ...item, active: false } : item
    );

    setFilterBy((f) => ({ ...f, statusList }));
  };

  const handlePaginationChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleShowInvoicePreview = (invoice: InvoiceAttachmentResponse) => {
    setInvoicePreview(invoice);
    setInvoicePreviewVisible(true);
  };

  const handleHideInvoicePreview = () => {
    setInvoicePreviewVisible(false);
  };

  const handleShowPreview = (value: boolean) => {
    setOpenPreview(value);
  };

  const handleUpdatePersistedInvoices = async () => {
    try {
      if (clientId && currentPeriodYear && date && financialYear) {
        const result = await asResultClass(
          sdk.sendSqsCustomerInvoicesEvent({ clientid: clientId })
        );
        if (result.err && isApiErrorType(result.val) && !result.val.handled) {
          if (result.val.status === 403) {
            dispatch(
              addGlobalMessage(
                'error',
                'hidden.customerinvoices.not.authorized'
              )
            );
          } else {
            dispatch(
              addGlobalMessage('error', 'hidden.invoices.FetchStatus.error')
            );
          }
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Wrapper>
      {invoicePreview && (
        <PdfStringModal
          isVisible={invoicePreviewVisible}
          fileContent={invoicePreview.fileContent}
          onClose={handleHideInvoicePreview}
        />
      )}
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />
      <PeriodPicker
        key={period.start}
        clientId={clientId}
        periods={groupedPeriods}
        onPeriodChange={handlePeriodSelect}
      />
      {isLoading ? (
        <LoadingWrapper>
          <LoadingPlaceholder />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <TableWrapper>
            <FiltersWrapper>
              <InvoiceFilters
                filters={filterBy.statusList}
                filterAction={handleStatusSelect}
                filterDeleteAction={handleStatusDeSelect}
              />
              {invoicesUpdateState && (
                <DateWrapper>
                  <Tooltip
                    title={formatMessage({
                      id: `hidden.customerinvoices.state.${invoicesUpdateState}`,
                      defaultMessage: 'Incorrect state',
                    })}
                  >
                    {getUpdateState(invoicesUpdateState)}
                  </Tooltip>
                  <Typography variant="body1" color="textSecondary">
                    {initData?.reportData.updateDate
                      ? parseFormat(
                          initData?.reportData.updateDate,
                          'yyyy-MM-dd HH:mm'
                        )
                      : ''}
                  </Typography>
                </DateWrapper>
              )}
              <OptionsWrapper>
                <Button
                  label={formatMessage({
                    id: 'reconciliation.hidden_row.customerInvoices.preview.show',
                  })}
                  variant="outlined"
                  size="medium"
                  onClick={() => handleShowPreview(true)}
                />

                {initData && (
                  <PrintButton
                    data={initData.reportData}
                    account={account}
                    hasSpecifications={!!legacySpecifications.length}
                  />
                )}
                {invoicesUpdateState === 'running' ? (
                  <Spinner size={24} />
                ) : (
                  <IconButton
                    size="small"
                    onClick={handleUpdatePersistedInvoices}
                    disabled={isLoading}
                  >
                    <UpdateIcon />
                  </IconButton>
                )}
              </OptionsWrapper>
            </FiltersWrapper>
            {initData?.invoices && (
              <InvoicesPreviewModal
                data={initData.reportData}
                period={period}
                handleShow={handleShowPreview}
                open={openPreview}
              />
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((head) => (
                      <TableCell key={head.id}>{formatMessage(head)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell size="small">
                        {clientId && (
                          <InvoicePreviewButton
                            clientId={clientId}
                            invoiceId={invoice.id}
                            onFetchDone={handleShowInvoicePreview}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.invoiceNumber || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.ocr || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.customerNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.customerName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {reformat(invoice.date, 'yyyyMMdd', 'yyyy-MM-dd')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {ccyFormat(invoice.total)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {ccyFormat(invoice.balance)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.currency}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.dueAt || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.paidAt || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {formatMessage({
                            id: `hidden.invoices.status.${invoice.status}`,
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={6} />
                    <TableCell>
                      <Typography variant="body1">Total</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">
                        {ccyFormat(balanceTotal)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {paginatedData.length > 0 && (
              <PaginatorWrapper>
                <Paginator
                  pageNumber={pageNumber}
                  totalElementCount={filteredData.length}
                  handlePaginationChange={handlePaginationChange}
                />
              </PaginatorWrapper>
            )}
          </TableWrapper>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default CustomerInvoicesView;
