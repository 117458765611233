import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import styled from '@emotion/styled';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import { endOfMonth } from 'date-fns';

import { useApiSdk, asResultClass, isApiErrorType } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { addGlobalMessage, setSupplierInvoices } from 'redux/actions';
import PeriodSummary from '_shared/components/PeriodSummary';
import Paginator from '_shared/components/Paginator';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '_shared/components/Table/BorderTable';
import { PdfStringModal } from '_shared/components/Modal';
import { ccyFormat, reformatDate } from '@agoy/common';
import { formatPeriodToMonthAndYear } from 'utils';
import { getPeriodYear } from 'utils/periodManipulation';
import { paginateArray } from 'utils/SieParser/transactionUtils';
import {
  SupplierInvoice,
  InputData,
  SupplierInvoicesResponse,
  InvoiceAttachmentResponse,
  InvoicesFilter,
  LastFetchedDataStateType,
} from '_reconciliation/types';
import { TimePeriod } from '@agoy/document';
import { UnreachableCaseError } from 'utils/types';
import { format, parseFormat, isSameOrAfter, parse } from '@agoy/dates';
import { isEqual } from 'lodash';

import Button from '_shared/components/Buttons/Button';
import InvoiceFilters from './InvoiceFilters';
import filterInvoices from './supplierInvoiceUtils';
import InvoicePreviewButton from './InvoicePreviewButton';
import PeriodPicker from '../PeriodPicker';
import { InvoicesPreviewModal } from './InvoicesPreview';
import PrintButton from './PrintButton';
import PeriodDataContext from '../PeriodDataContext';

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const LoadingWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`;

const TableWrapper = styled.div`
  flex: 1;

  display: grid;
  grid-template-rows: max-content max-content 1fr;

  overflow-x: auto;
`;

const FiltersWrapper = styled.div`
  display: flex;
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(1)}px 0;
  justify-content: space-between;
  align-items: center;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Spinner = styled(CircularProgress)`
  top: 15%;
  left: 40%;
`;

const DateWrapper = styled.div`
  margin-left: auto;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

const HEADER_DESCRIPTORS: MessageDescriptor[] = [
  { id: 'hidden.invoices.Id' },
  { id: 'hidden.invoices.InvoiceNumber' },
  { id: 'hidden.invoices.SupplierNumber' },
  { id: 'hidden.invoices.SupplierName' },
  { id: 'hidden.invoices.InvoiceDate' },
  { id: 'hidden.invoices.InvoiceTotalAmount' },
  { id: 'hidden.invoices.InvoiceSaldo' },
  { id: 'hidden.invoices.InvoiceCurrency' },
  { id: 'hidden.invoices.InvoiceDueDate' },
  { id: 'hidden.invoices.InvoicePaymentDate' },
  { id: 'hidden.invoices.InvoiceStatus' },
];

type SupplierInvoiceViewProps = {
  userData: InputData;
  account: string;
};

const SupplierInvoiceView = ({
  userData,
  account,
}: SupplierInvoiceViewProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const { clientId, groupedPeriods, financialYear, period } =
    useContext(PeriodDataContext);
  const notificationService = useContext(NotificationContext);

  // invoice attachment preview state
  const [invoicePreviewVisible, setInvoicePreviewVisible] = useState(false);
  const [invoicePreview, setInvoicePreview] =
    useState<InvoiceAttachmentResponse>();

  const [filterBy, setFilterBy] = useState<InvoicesFilter>({
    account,
    periods: {},
    statusList: [],
  });
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState<SupplierInvoice[]>([]);
  const [filteredData, setFilteredData] = useState<SupplierInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [invoicesUpdateState, setInvoicesUpdateState] =
    useState<LastFetchedDataStateType>(undefined);
  const [initData, setInitData] = useState<
    SupplierInvoicesResponse | undefined
  >();

  const financialYears = useSelector(
    (state) => state.customers[clientId].rawFinancialYears
  );

  const yearsState = useSelector((state) => {
    return state.accountingView.clients[clientId].years;
  });

  const dates = useMemo(() => {
    const years: { [year: string]: string } = {};

    Object.keys(filterBy.periods).forEach((key) => {
      const finYear = financialYears.find((item) =>
        item.periods?.find((p) => parseFormat(p.start, 'yyyyMM') === key)
      );

      if (filterBy.periods[key] && finYear) {
        const finYearPeriod = TimePeriod.fromISODates(finYear).value;

        if (
          !years[finYearPeriod] ||
          isSameOrAfter(parse(key), parse(years[finYearPeriod]))
        ) {
          years[finYearPeriod] = format(endOfMonth(parse(key)), 'yyyy-MM-dd');
        }
      }
    });

    return years;
  }, [filterBy.periods, financialYears]);

  const invoices = useMemo(() => {
    const invoicesList: SupplierInvoice[] = [];

    Object.keys(dates).forEach((year) => {
      invoicesList.push(...(yearsState[year]?.supplierInvoices.invoices || []));
    });

    return invoicesList;
  }, [dates, yearsState]);

  const {
    ub,
    ib,
    psaldo,
    saldo: userDataSaldo,
    legacySpecifications = [],
  } = userData || {};
  const currentPeriodYear = getPeriodYear(period.start);
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;
  const date = period.end;

  const fetchInvoicesData = useCallback(
    async (fetchDate: string) => {
      const result = await asResultClass<SupplierInvoicesResponse>(
        sdk.getSupplierInvoices({
          clientid: clientId,
          date: fetchDate,
        })
      );
      if (result.ok) {
        return result.val;
      }

      return undefined;
    },
    [sdk, clientId]
  );

  const getInvoices = useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await Promise.all(
        Object.keys(dates).map(async (year) => {
          const response = await fetchInvoicesData(dates[year]);

          if (response) {
            setInitData(response);

            dispatch(setSupplierInvoices(clientId, year, response));
          }

          return response;
        })
      );

      setInvoicesUpdateState(result[0]?.reportData.updateState);
    } catch {
      dispatch(
        addGlobalMessage('error', 'hidden.supplierInvoices.FetchStatus.error')
      );
    } finally {
      setIsLoading(false);
    }
  }, [dates, fetchInvoicesData, dispatch, clientId]);

  const getUpdateState = (state: LastFetchedDataStateType) => {
    switch (state) {
      case 'completed': {
        return <AccessTimeIcon />;
      }
      case 'running': {
        return <UpdateIcon />;
      }
      case 'invoices_fail':
      case 'payments_fail':
      case undefined: {
        return <ReportProblemOutlinedIcon />;
      }
      default:
        throw new UnreachableCaseError(state);
    }
  };
  // EFFECTS
  useEffect(() => {
    // update date when other period in same account is selected
    setFilterBy((f) => {
      const periods = groupedPeriods.reduce(
        (result, p) => ({
          ...result,
          [reformatDate(p.start, 'yyyy-MM-dd', 'yyyyMM')]: true,
        }),
        {}
      );
      if (isEqual(periods, f.periods)) {
        return f;
      }
      return {
        ...f,
        periods,
      };
    });
  }, [groupedPeriods]);

  useEffect(() => {
    const data = filterInvoices({
      data: invoices,
      filter: filterBy,
    });
    setFilteredData(data);

    // calculate sum of all transactions
    setBalanceTotal(
      data.reduce((acc, invoice) => {
        return acc + invoice.balance;
      }, 0)
    );

    // reset pagination
    setPageNumber(1);
  }, [invoices, filterBy]);

  // paginate data on FE
  useEffect(() => {
    setPaginatedData(
      paginateArray({
        pageNumber,
        array: filteredData,
      })
    );
  }, [pageNumber, filteredData]);

  useEffect(() => {
    if (!clientId) {
      return () => {};
    }

    getInvoices();
    const sub = notificationService?.subscribe(
      {
        topic: 'fortnox-supplier-invoices',
        clientId,
      },
      (result) => {
        if (result.ok && result.val.topic === 'fortnox-supplier-invoices') {
          // if update finished to reload invocies from DB
          switch (result.val.status) {
            case 'completed': {
              getInvoices();
              dispatch(
                addGlobalMessage(
                  'success',
                  'hidden.supplierInvoices.FetchStatus.success'
                )
              );
              break;
            }
            case 'running': {
              setInvoicesUpdateState('running');
              break;
            }
            default: {
              setInvoicesUpdateState(result.val.status);
              dispatch(
                addGlobalMessage(
                  'error',
                  'hidden.supplierInvoices.FetchStatus.error'
                )
              );
              break;
            }
          }
        }
      }
    );

    return () => {
      sub?.unsubscribe();
    };
  }, [clientId, getInvoices, notificationService, dispatch]);

  useEffect(() => {
    if (initData) {
      const statusList = initData.statusList.map((statusTitle) => ({
        title: statusTitle,
        active: false,
      }));
      setFilterBy((f) => ({ ...f, statusList }));
    }
  }, [initData]);

  // CALLBACKS
  const handlePeriodSelect = useCallback((periods) => {
    setFilterBy((f) =>
      isEqual(periods, f.periods)
        ? f
        : {
            ...f,
            periods: { ...periods },
          }
    );
  }, []);

  const handleStatusToggle = (statusString, setActive) => {
    const statusList = filterBy.statusList.map((item) => {
      if (
        (item.title === 'unpaid_overdue' &&
          statusString === 'unpaid' &&
          setActive) ||
        item.title === statusString
      ) {
        return { ...item, active: setActive };
      }
      return item;
    });

    setFilterBy((f) => ({ ...f, statusList }));
  };

  const handlePaginationChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleShowInvoicePreview = (invoice: InvoiceAttachmentResponse) => {
    setInvoicePreview(invoice);
    setInvoicePreviewVisible(true);
  };

  const handleHideInvoicePreview = () => {
    setInvoicePreviewVisible(false);
  };

  const handleShowPreview = (value: boolean) => {
    setOpenPreview(value);
  };

  const handleUpdatePersistedInvoices = async () => {
    try {
      if (clientId && currentPeriodYear && date && financialYear) {
        // also send event to update supplier invoices
        const result = await asResultClass(
          sdk.sendSqsSupplierInvoicesEvent({ clientid: clientId })
        );
        if (result.err && isApiErrorType(result.val) && !result.val.handled) {
          if (result.val.status === 403) {
            dispatch(
              addGlobalMessage(
                'error',
                'hidden.supplierinvoices.not.authorized'
              )
            );
          } else {
            dispatch(
              addGlobalMessage('error', 'hidden.invoices.FetchStatus.error')
            );
          }
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Wrapper>
      {invoicePreview && (
        <PdfStringModal
          isVisible={invoicePreviewVisible}
          fileContent={invoicePreview.fileContent}
          onClose={handleHideInvoicePreview}
        />
      )}
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />
      <PeriodPicker
        key={period.start}
        clientId={clientId}
        periods={groupedPeriods}
        onPeriodChange={handlePeriodSelect}
      />
      {isLoading ? (
        <LoadingWrapper>
          <LoadingPlaceholder />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <TableWrapper>
            <FiltersWrapper>
              <InvoiceFilters
                filters={filterBy.statusList}
                filterAction={(status) => handleStatusToggle(status, true)}
                filterDeleteAction={(status) =>
                  handleStatusToggle(status, false)
                }
              />
              {invoicesUpdateState && (
                <DateWrapper>
                  <Tooltip
                    title={formatMessage({
                      id: `hidden.customerinvoices.state.${invoicesUpdateState}`,
                      defaultMessage: 'Incorrect state',
                    })}
                  >
                    {getUpdateState(invoicesUpdateState)}
                  </Tooltip>
                  <Typography variant="body1" color="textSecondary">
                    {initData?.reportData.updateDate
                      ? parseFormat(
                          initData?.reportData.updateDate,
                          'yyyy-MM-dd HH:mm'
                        )
                      : ''}
                  </Typography>
                </DateWrapper>
              )}
              <OptionsWrapper>
                <Button
                  label={formatMessage({
                    id: 'reconciliation.hidden_row.supplierInvoices.preview.show',
                  })}
                  onClick={() => handleShowPreview(true)}
                  size="medium"
                  variant="outlined"
                />

                {initData && (
                  <PrintButton
                    account={account}
                    data={initData.reportData}
                    hasSpecifications={!!legacySpecifications.length}
                  />
                )}

                {invoicesUpdateState === 'running' ? (
                  <Spinner size={24} />
                ) : (
                  <IconButton
                    size="small"
                    onClick={handleUpdatePersistedInvoices}
                    disabled={isLoading}
                  >
                    <UpdateIcon />
                  </IconButton>
                )}
              </OptionsWrapper>
            </FiltersWrapper>
            {initData?.invoices && (
              <InvoicesPreviewModal
                data={initData.reportData}
                period={period}
                handleShow={handleShowPreview}
                open={openPreview}
              />
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {HEADER_DESCRIPTORS.map((descriptor) => (
                      <TableCell key={descriptor.id}>
                        {formatMessage(descriptor)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell>
                        {clientId && (
                          <InvoicePreviewButton
                            clientId={clientId}
                            invoiceId={invoice.id}
                            onFetchDone={handleShowInvoicePreview}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.invoiceNumber || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.supplierNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.supplierName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{invoice.date}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {ccyFormat(invoice.total)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {ccyFormat(invoice.balance)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.currency}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.dueAt || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {invoice.paidAt || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {formatMessage({
                            id: `hidden.invoices.status.${invoice.status}`,
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell>
                      <Typography variant="body1">Total</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">
                        {ccyFormat(balanceTotal)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {paginatedData.length > 0 && (
              <PaginatorWrapper>
                <Paginator
                  pageNumber={pageNumber}
                  totalElementCount={filteredData.length}
                  handlePaginationChange={handlePaginationChange}
                />
              </PaginatorWrapper>
            )}
          </TableWrapper>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default SupplierInvoiceView;
