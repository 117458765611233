import React, { useState, useCallback, useMemo } from 'react';
import { ApiReturnType, asResultClass, useApiSdk } from 'api-sdk';
import {
  ClientInformation as ClientInformationType,
  ClientInformationField,
  ClientInformationUpdate,
} from '@agoy/api-sdk-core';

type ClientInformation = {
  [clientId: string]: ClientInformationType;
};

interface ClientInfoContextType {
  clientInformation: ClientInformation;
  fetchClientInformation: (
    clientId: string,
    updateState?: boolean
  ) => Promise<ApiReturnType<'getClientInformation'>>;
  patchClientInformation: (
    clientId: string,
    clientInformation: ClientInformationUpdate,
    source: ClientInformationField['source']
  ) => Promise<ApiReturnType<'patchClientInformation'>>;
}

export const ClientInfoContext = React.createContext<ClientInfoContextType>({
  clientInformation: {},
  fetchClientInformation: () => Promise.resolve({}),
  patchClientInformation: () => Promise.resolve({}),
});

type ClientInfoContextProviderProps = {
  defaultClientInformation?: ClientInformation;
  children: React.ReactNode;
};

const ClientInfoContextProvider = ({
  defaultClientInformation,
  children,
}: ClientInfoContextProviderProps): JSX.Element => {
  const [clientInformation, setClientInformation] = useState<ClientInformation>(
    defaultClientInformation || {}
  );

  const sdk = useApiSdk();

  const fetchClientInformation = useCallback(
    async (
      clientId: string,
      updateState = true
    ): Promise<ApiReturnType<'getClientInformation'>> => {
      const registerData = await asResultClass(
        sdk.getClientInformation({ clientid: clientId })
      );

      if (registerData.err) throw new Error('fetching failed');

      if (updateState) {
        setClientInformation((currentData) => ({
          ...currentData,
          [clientId]: registerData.val,
        }));
      }

      return registerData.val;
    },
    [sdk]
  );

  const patchClientInformation = useCallback(
    async (
      clientId: string,
      data: ClientInformationUpdate,
      source: 'user' | 'fortnox'
    ) => {
      const result = await asResultClass(
        sdk.patchClientInformation({
          clientid: clientId,
          requestBody: data,
          source,
        })
      );
      await fetchClientInformation(clientId);

      return result;
    },
    [fetchClientInformation, sdk]
  );

  const value = useMemo(
    () => ({
      clientInformation,
      fetchClientInformation,
      patchClientInformation,
    }),
    [clientInformation, fetchClientInformation, patchClientInformation]
  );

  return (
    <ClientInfoContext.Provider value={value}>
      {children}
    </ClientInfoContext.Provider>
  );
};

export default ClientInfoContextProvider;
